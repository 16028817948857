<template>
  <div class="container mx-auto py-16 ">
    <h1 class="text-4xl mb-4 font-merri p-6 pt-0 text-center ">
      Privacy Policy
    </h1>
    <div class="w-full sm:w-2/3 mx-auto">
      <p>
        Admittedly this is pretty boring stuff, feel free to have a good read if
        you need help to sleep/catnap
      </p>
      <div class="text-xl font-semibold mb-8">
        An Introduction
      </div>
      <p>
        Your privacy is important to us, so to:
      </p>
      <p>Let you know about your privacy rights;</p>
      <p>
        How the law protects you, and Enable you to understand how
        thecoolcatclub.com looks after your personal data
      </p>
      <p>
        We have prepared this Privacy Policy to the best of our ability. Please
        use the guide to specific sections below to easily find what you are
        looking for.
      </p>
      <p class="font-semibold">1. IMPORTANT INFORMATION AND WHO WE ARE</p>
      <p class="font-semibold">2. THE DATA WE COLLECT ABOUT YOU</p>
      <p class="font-semibold">3. HOW IS YOUR PERSONAL DATA COLLECTED</p>
      <p class="font-semibold">4. HOW AND WHY WE USE YOUR PERSONAL DATA</p>
      <p class="font-semibold">5. DISCLOSURES OF YOUR PERSONAL DATA</p>
      <p class="font-semibold">6. INTERNATIONAL TRANSFERS</p>
      <p class="font-semibold">7. DATA SECURITY</p>
      <p class="font-semibold">8. DATA RETENTION</p>
      <p class="font-semibold">9. YOUR LEGAL RIGHTS</p>
      <p class="font-semibold">10. GLOSSARY</p>

      <p class="font-semibold">1. IMPORTANT INFORMATION AND WHO WE ARE</p>
      <p class="font-semibold">Purpose of this privacy notice</p>
      <p>
        Our privacy notice aims to give you information on how
        thecoolcatclub.com collects and processes your personal data through
        your use of our website and related services, including any personal
        data you may provide through this website when you create an account
        with us or purchase our products.
      </p>
      <p>
        For the avoidance of doubt, this privacy policy and any references to
        personal data herein do not include information relating to your cat.
        For further information about how we use information relating to your
        cat, including use of your cat’s photo, please see the
        thecoolcatclub.com
        <router-link
          class="underline text-teal-400"
          :to="{ name: 'terms conditions' }"
          >Terms &amp; Conditions.</router-link
        >
      </p>
      <p>
        Given the nature of our services, we do not knowingly collect data
        relating to children. It is important that you read this privacy notice
        together with any other notices we may provide you so that you are fully
        aware of how and why we are using your data. This privacy notice does
        not override our other notices and may be updated from time to time.
      </p>
      <p class="font-semibold">Data Controller</p>
      <p>
        Valtrus Ltd control and are responsible for the personal data that we
        hold about you. Our company correspondence details and registered
        company number and are included in our terms and conditions.
      </p>
      <p>
        If you have any questions about this privacy notice, including any
        requests to exercise your legal rights, please contact us using the
        details set out below.
      </p>
      <p>
        We would appreciate the chance to deal with any data protection concerns
        you may have before you approach the Information Commissioner's Office
        (ICO) so please contact us at team@thecoolcatclub.com in the first
        instance.
      </p>
      <p>
        You have the right to make a complaint at any time to the Information
        Commissioner's Office (ICO), the UK supervisory authority for data
        protection issues (<a
          class="underline text-teal-400"
          href="http://www.ico.org.uk"
          rel="noopener"
          >www.ico.org.uk</a
        >).
      </p>
      <p class="font-semibold">
        Changes to the privacy notice and your duty to inform us of changes
      </p>
      <p>
        It is important that the personal data we hold about you is accurate and
        current. Please keep us informed if your personal data changes during
        your relationship with us.
      </p>
      <p class="font-semibold">Third-party links</p>
      <p>
        This website may include links to third-party websites, plug-ins and
        applications. Clicking on those links or enabling those connections may
        allow third parties to collect or share data about you. We do not
        control these third-party websites and are not responsible for their
        privacy statements. When you leave our website, we encourage you to read
        the privacy notice of every website you visit.
      </p>
      <p class="font-semibold">2. THE DATA WE COLLECT ABOUT YOU</p>
      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed (anonymous data)
      </p>
      <p>
        We may collect, use, store and transfer different kinds of personal data
        about you which we have grouped together follows:
      </p>
      <p>
        <span class="font-semibold">Identity Data</span> includes first name,
        last name, username or similar identifier (including your facial
        features if you provide them to us in a photograph).
      </p>
      <p>
        <span class="font-semibold">Contact Data</span> includes billing
        address, delivery address, email address and telephone numbers.
      </p>
      <p>
        <span class="font-semibold">Financial Data</span> includes bank account
        and payment card details.
      </p>
      <p>
        <span class="font-semibold">Transaction Data</span> includes details
        about payments to and from you and other details of products and
        services you have purchased from us, which includes anything we’ve sent
        or delivered to you.
      </p>
      <p>
        <span class="font-semibold">Technical Data</span> includes details of
        visits to our site, internet protocol (IP) address, your login data,
        browser type and version, time zone setting and location, browser
        plug-in types and versions, operating system and platform and other
        technology on the devices you use to access this website.
      </p>
      <p>
        <span class="font-semibold">Profile Data</span> includes your username
        and password, purchases or orders made by you, your interests,
        preferences, feedback, communications you’ve sent to us or we’ve sent to
        you and survey responses.
      </p>
      <p>
        <span class="font-semibold">Usage Data</span> includes information about
        how you use our website, communications (including email and social
        media), products and services.
      </p>
      <p>
        <span class="font-semibold">Marketing and Communications Data</span>
        includes your preferences in receiving marketing from us and our third
        parties and your communication preferences.
      </p>
      <p>
        We also collect, use and share Aggregated Data such as statistical or
        demographic data for any purpose. Aggregated Data may be derived from
        your personal data but is not considered personal data in law as this
        data does not directly or indirectly reveal your identity. For example,
        we may aggregate your Usage Data to calculate the percentage of users
        accessing a specific website feature. However, if we combine or connect
        Aggregated Data with your personal data so that it can directly or
        indirectly identify you, we treat the combined data as personal data
        which will be used in accordance with this privacy notice.
      </p>
      <p>
        We do not usually collect any Special Categories of Personal Data about
        you (this includes details about your race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        trade union membership, information about your health and genetic and
        biometric data). Nor do we collect any information about criminal
        convictions and offences. In some circumstances, we may inadvertently
        record Special Categories of Personal Data if voluntarily disclosed to
        us in email or over the phone. Under such circumstances, any Special
        Categories of Personal Data will be treated in accordance with your
        rights.
      </p>
      <p class="font-semibold">If you fail to provide personal data</p>
      <p>
        Where we need to collect personal data by law, or under the terms of a
        contract we have with you and you fail to provide that data when
        requested, we may not be able to perform the contract we have or are
        trying to enter into with you (for example, to provide you with goods or
        services). In this case, we may have to cancel a product or service you
        have with us but we will notify you if this is the case at the time.
      </p>
      <p class="font-semibold">3. HOW IS YOUR PERSONAL DATA COLLECTED</p>
      <p>
        We use different methods to collect data from and about you including
        through:
      </p>
      <p>
        Direct correspondence. You may give us your Identity, Contact and/or
        Financial Data by filling in forms or by contacting us via social media,
        our website, post, phone, text, email or otherwise. This includes
        personal data you provide when you:
      </p>
      <p>Complete our sign-up questions and consultation;</p>
      <p>Create or start creating a user account on our website;</p>
      <p>
        Provide details of any changes to your account that you may notify us of
        from time to time
      </p>
      <p>Subscribe to our service;</p>
      <p>Request marketing to be sent to you;</p>
      <p>Subscribe to a promotion or survey; or</p>
      <p>
        Communicate with us, whether it’s giving us some feedback, asking us any
        questions or providing us with any suggestions about our products and
        services.
      </p>
      <p>
        Automated technologies or interactions. As you interact with our
        website, we may automatically collect Technical Data about your
        equipment, browsing actions and patterns. We collect this personal data
        by using cookies and other similar technologies. We may also receive
        Technical Data about you if you visit other websites employing our
        cookies. Please see our cookie policy for further details.
      </p>
      <p>
        Third parties or publicly available sources. We may receive personal
        data about you from various third parties including Technical Data from
        the following parties:
      </p>
      <p class="ml-4">(a) analytics providers;</p>
      <p class="ml-4">(b) advertising networks; and</p>
      <p class="ml-4">(c) search information providers.</p>
      <p class="font-semibold">4. HOW AND WHY WE USE YOUR PERSONAL DATA</p>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
      </p>
      <p>
        Where we need to perform the contract we are about to enter into or have
        entered into with you.
      </p>
      <p>
        Where it is in our legitimate interests (or those of a third party) and
        your interests do not override that interest.
      </p>
      <p>Where we need to comply with a legal or regulatory obligation.</p>
      <p>
        Generally we do not rely on consent as a legal basis for processing your
        personal data other than in relation to sending direct marketing
        communications to you via email or text message from us. You have the
        right to withdraw consent to marketing at any time by contacting us.
      </p>
      <p>
        The points below set out how we use your data and the lawful basis for
        doing so.
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">To register you as a new customer</p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Financial</p>
      <p>Data required for:</p>
      <p>Performance of a contract with you</p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To contact you regarding completion of the registration process
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Usage</p>
      <p>Data required for:</p>
      <p>Performance of a contract with you</p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">To process and deliver your order including:</p>
      <p class="font-semibold">(a) Manage payments, fees and charges,</p>
      <p class="font-semibold">(b) Collect and recover money owed to us</p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Financial</p>
      <p class="ml-4">(d) Transaction</p>
      <p class="ml-4">(e) Marketing and Communications</p>
      <p>Data required for:</p>
      <p>(a) Performance of a contract with you</p>
      <p>
        (b) Necessary for our legitimate interests (to recover debts due to us)
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To manage our relationship with you which will include:
      </p>
      <p class="font-semibold">
        (a) Notifying you about changes to our terms or privacy policy
      </p>
      <p class="font-semibold">
        (b) Asking you to leave a review or take a survey
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Profile</p>
      <p class="ml-4">(d) Marketing and Communications</p>
      <p>Data required for:</p>
      <p>(a) Performance of a contract with you</p>
      <p>(b) Necessary to comply with a legal obligation,</p>
      <p>
        (c) Necessary for our legitimate interests (to keep our records updated
        and to study how customers use our products/services)
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">To enable you to complete a survey</p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Profile</p>
      <p class="ml-4">(d) Usage</p>
      <p class="ml-4">(e) Marketing and Communications</p>
      <p>Data required for:</p>
      <p>(a) Performance of a contract with you,</p>
      <p>
        (b) Necessary for our legitimate interests (to study how customers use
        our products/services, to develop them and grow our business)
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To administer and protect our business and this website (including
        troubleshooting, data analysis, testing, system maintenance, support,
        reporting and hosting of data)
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Technical</p>
      <p>Data required for:</p>
      <p>
        (a) Necessary for our legitimate interests (for running our business,
        provision of administration and IT services, network security, to
        prevent fraud and in the context of a business reorganisation or group
        restructuring exercise),
      </p>
      <p>(b) Necessary to comply with a legal obligation</p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To deliver relevant website content and advertisements to you and
        measure or understand the effectiveness of the advertising we serve to
        you
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Profile</p>
      <p class="ml-4">(d) Usage</p>
      <p class="ml-4">(e) Marketing and Communications</p>
      <p class="ml-4">(f) Technical</p>
      <p>Data required for:</p>
      <p>
        Necessary for our legitimate interests (to study how customers use our
        products/services, to develop them, to grow our business and to inform
        our marketing strategy)
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To use data analytics to improve our website, products/services,
        marketing, customer relationships and
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Contact</p>
      <p class="ml-4">(b) Technical</p>
      <p class="ml-4">(c) Usage</p>
      <p>Data required for:</p>
      <p>
        Necessary for our legitimate interests (to define types of customers for
        our products and services, to keep our website updated and relevant, to
        develop our business and to inform our marketing strategy)
      </p>
      <p class="font-semibold">Activity</p>
      <p class="font-semibold">
        To make suggestions and recommendations to you about goods or services
        that may be of interest to
      </p>
      <p>Data required:</p>
      <p class="ml-4">(a) Identity</p>
      <p class="ml-4">(b) Contact</p>
      <p class="ml-4">(c) Technical</p>
      <p class="ml-4">(d) Usage</p>
      <p class="ml-4">(e) Profile</p>
      <p>Data required for:</p>
      <p>
        Necessary for our legitimate interests (to develop our products/services
        and grow our business)
      </p>
      <p>
        Note that we may process your personal data for more than one lawful
        ground depending on the specific purpose for which we are using your
        data. Please contact us if you need details about the specific legal
        ground we are relying on to process your personal data where more than
        one ground has been set out in the list above. For the most part, we’ll
        be using your data to fulfil our contract with you. In providing any
        peripheral services, we will only use your data where it is in our
        legitimate interests, such as improving our goods/services, which we
        hope will benefit you and your cat.
      </p>
      <p class="font-semibold">Marketing</p>
      <p>
        We provide you with choices regarding certain personal data uses,
        particularly around marketing and advertising. We make certain decisions
        about how we communicate with you and you can adjust by contacting us
        anytime.
      </p>
      <p class="font-semibold">Promotional offers from us</p>
      <p>
        We may use your Identity, Contact, Technical, Usage and Profile Data to
        form a view on what we think you may want or need, or what may be of
        interest to you (and your cat). This is how we decide which products,
        services and offers may be relevant for you.
      </p>
      <p>
        You will receive marketing communications from us if you have requested
        information from us or purchased goods or services from us or if you
        provided us with your details when you started our signup process and,
        in each case, you have not opted out of receiving that marketing
      </p>
      <p class="font-semibold">Third-party marketing</p>
      <p>
        We will get your express opt-in consent before we share your personal
        data with any company outside the thecoolcatclub.com for marketing
        purposes.
      </p>
      <p class="font-semibold">Opting out</p>
      <p>
        You can ask us to stop sending you marketing messages at any time by
        emailing us at team@thecoolcatclub.com or by following the unsubscribe
        links on any marketing message sent to you via email.
      </p>
      <p>
        Where you opt out of receiving these marketing messages, this will not
        apply to personal data provided to us as a result of a product/service
        purchase. We will continue to communicate with you for reasons essential
        to the fulfilment of any orders (this includes information relating to
        your account and deliveries), you cannot opt out of any such messages.
      </p>
      <p class="font-semibold">Cookies</p>
      <p>
        You can set your browser to refuse all or some browser cookies, or to
        alert you when websites set or access cookies. If you disable or refuse
        cookies, please note that some parts of this website may become
        inaccessible or not function properly.
      </p>
      <p class="font-semibold">Change of purpose</p>
      <p>
        We will only use your personal data for the purposes for which we
        collected it, unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please contact us.
      </p>
      <p>
        If we need to use your personal data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
      </p>
      <p>
        Please note that we may process your personal data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </p>
      <p class="font-semibold">5. DISCLOSURES OF YOUR PERSONAL DATA</p>
      <p>
        We may have to share your personal data with the parties set out below
        for the purposes set out in the table in paragraph 4 above.
      </p>
      <p>
        Delivery services, such as DPD or others, for the fulfilment of your
        orders.
      </p>
      <p>
        Service providers who provide IT and system administration services.
      </p>
      <p>
        Professional advisers including lawyers, bankers, auditors and insurers
        based who provide consultancy, banking, legal, insurance and accounting
        services.
      </p>
      <p>
        HM Revenue &amp; Customs, regulators and other authorities who require
        reporting of processing activities in certain circumstances.
      </p>
      <p>
        Third parties to whom we may choose to sell, transfer, or merge parts of
        our business or our assets.
      </p>
      <p>
        Alternatively, we may seek to acquire other businesses or merge with
        them.
      </p>
      <p>
        We require all third parties to respect the security of your personal
        data and to treat it in accordance with the law. We only permit third
        parties to process your personal data for specified purposes and in
        accordance with our instructions. Third-parties may contact you directly
        in connection to the provision of our goods/services e.g. relating to
        the delivery of your orders – such communications may include
        satisfaction surveys relating to your delivery. These communications
        will be covered by the third party’s privacy policy.
      </p>
      <p class="font-semibold">6. INTERNATIONAL TRANSFERS</p>
      <p>
        Some of our servers and processors are based outside the European
        Economic Area (EEA) so their processing of your personal data will
        involve a transfer of data outside the EEA.
      </p>
      <p>
        Whenever we transfer your personal data out of the EEA, we ensure a
        similar degree of protection is afforded to it by ensuring at least one
        of the following safeguards is implemented:
      </p>
      <p>
        That that country has been deemed to provide an adequate level of
        protection for personal data by the European Commission.
      </p>
      <p>
        We use model clauses approved by the European Commission which give
        personal data the same protection it has in Europe.
      </p>
      <p>
        Where we use providers based in the US, we may transfer data to them if
        they are part of the Privacy Shield which requires them to provide
        similar protection to personal data shared between the Europe and the
        US.
      </p>
      <p>
        Please contact us at
        <a class="text-teal-400 underline" href="mailto:team@thecoolcatclub.com"
          >team@thecoolcatclub.com</a
        >
        if you want further information on the specific mechanism used by us
        when transferring your personal data out of the EEA.
      </p>
      <p class="font-semibold">7. DATA SECURITY</p>
      <p>
        We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used or accessed in an
        unauthorised way, altered or disclosed.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator of a breach
        where we are legally required to do so.
      </p>
      <p class="font-semibold">8. DATA RETENTION</p>
      <p class="font-semibold">How long will you use my personal data for?</p>
      <p>
        We will only retain your personal data for as long as necessary to
        fulfil the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>
      <p>
        In some circumstances you can ask us to delete your data: see 'Request
        erasure' below for further information.
      </p>
      <p>
        For Personal Data that is not required to be kept for legal purposes or
        where our legitimate interest is not overridden by your interests or
        fundamental rights and freedoms, we will retain your data for 24 months
        from the point at which you cancel your subscriptions with us and stop
        all interaction with thecoolcatclub.com. If you don’t complete our
        signup process for your first delivery, we will retain any Personal Data
        that you provide during the signup process for 12 months. If you have
        opted into receiving marketing messages from us, we will notify you via
        email prior to deleting your Personal Data so you have a chance to
        prevent the deletion and enable you to retain access to your account,
        your cat’s order profile and any correspondence with us.
      </p>
      <p>
        If you have opted out of marketing, please be aware that we will delete
        your Personal Data in accordance with the timeframes above without
        further notification to you.
      </p>
      <p>
        In some circumstances we may anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes in which case we may use this information indefinitely without
        further notice to you.
      </p>
      <p class="font-semibold">9. YOUR LEGAL RIGHTS</p>
      <p>
        Under certain circumstances, you have rights under data protection laws
        in relation to your personal data, these include:
      </p>
      <p>Request access to your personal data.</p>
      <p>Request correction of your personal data.</p>
      <p>Request erasure of your personal data.</p>
      <p>Object to processing of your personal data.</p>
      <p>Request restriction of processing your personal data.</p>
      <p>Request transfer of your personal data.</p>
      <p>Right to withdraw consent.</p>
      <p>
        If you wish to exercise any of the rights set out above, please contact
        us at
        <a class="text-teal-400 underline" href="mailto:team@thecoolcatclub.com"
          >team@thecoolcatclub.com</a
        >. You have the right to:
      </p>
      <p>
        <span class="font-semibold">Request access</span> to your personal data
        (commonly known as a "data subject access request"). This enables you to
        receive a copy of the personal data we hold about you and to check that
        we are lawfully processing it.
      </p>
      <p>
        <span class="font-semibold">Request correction</span> of the personal
        data that we hold about you. This enables you to have any incomplete or
        inaccurate data we hold about you corrected, though we may need to
        verify the accuracy of the new data you provide to us.
      </p>
      <p>
        <span class="font-semibold">Request erasure</span> of your personal
        data. This enables you to ask us to delete or remove personal data where
        there is no good reason for us continuing to process it. You also have
        the right to ask us to delete or remove your personal data where you
        have successfully exercised your right to object to processing (see
        below), where we may have processed your information unlawfully or where
        we are required to erase your personal data to comply with local law.
        Note, however, that we may not always be able to comply with your
        request of erasure for specific legal reasons which will be notified to
        you, if applicable, at the time of your request.
      </p>
      <p>
        <span class="font-semibold">Object to processing</span> of your personal
        data where we are relying on a legitimate interest (or those of a third
        party) and there is something about your particular situation which
        makes you want to object to processing on this ground as you feel it
        impacts on your fundamental rights and freedoms. You also have the right
        to object where we are processing your personal data for direct
        marketing purposes. In some cases, we may demonstrate that we have
        compelling legitimate grounds to process your information which override
        your rights and freedoms.
      </p>
      <p>
        <span class="font-semibold">Request restriction</span> of processing of
        your personal data. This enables you to ask us to suspend the processing
        of your personal data in the following scenarios: (a) if you want us to
        establish the data's accuracy; (b) where our use of the data is unlawful
        but you do not want us to erase it; (c) where you need us to hold the
        data even if we no longer require it as you need it to establish,
        exercise or defend legal claims; or (d) you have objected to our use of
        your data but we need to verify whether we have overriding legitimate
        grounds to use it.
      </p>
      <p>
        <span class="font-semibold">Request the transfer</span> of your personal
        data to you or to a third party. We will provide to you, or a third
        party you have chosen, your personal data in a structured, commonly
        used, machine-readable format. Note that this right only applies to
        automated information which you initially provided consent for us to use
        or where we used the information to perform a contract with you.
      </p>
      <p>
        <span class="font-semibold">Withdraw consent</span> at any time where we
        are relying on consent to process your personal data. However, this will
        not affect the lawfulness of any processing carried out before you
        withdraw your consent. If you withdraw your consent, we may not be able
        to provide certain products or services to you. We will advise you if
        this is the case at the time you withdraw your consent.
      </p>
      <p class="font-semibold">No fee usually required</p>

      <p>
        You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights). However, we may charge a reasonable
        fee if your request is clearly unfounded, repetitive or excessive.
        Alternatively, we may refuse to comply with your request in these
        circumstances.
      </p>
      <p class="font-semibold">What we may need from you</p>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>
      <p class="font-semibold">Time limit to respond</p>
      <p>
        We try to respond to all legitimate requests within 30 days.
        Occasionally it may take us longer than 30 days if your request is
        particularly complex or you have made a number of requests. In this
        case, we will notify you and keep you updated.
      </p>
      <p class="font-semibold">10. GLOSSARY</p>
      <p class="font-semibold">Lawful Basis</p>
      <p>
        <span class="font-semibold">Legitimate Interest</span> means the
        interest of our business in conducting and managing our business to
        enable us to give you the best service/product and the best and most
        secure experience. We make sure we consider and balance any potential
        impact on you (both positive and negative) and your rights before we
        process your personal data for our legitimate interests. We do not use
        your personal data for activities where our interests are overridden by
        the impact on you (unless we have your consent or are otherwise required
        or permitted to by law). You can obtain further information about how we
        assess our legitimate interests against any potential impact on you in
        respect of specific activities by contacting us.
      </p>
      <p>
        <span class="font-semibold">Performance of Contract</span> means
        processing your data where it is necessary for the performance of a
        contract to which you are a party or to take steps at your request
        before entering into such a contract.
      </p>
      <p class="font-semibold">Cookie Policy</p>
      <p>INFORMATION ABOUT OUR USE OF COOKIES</p>
      <p>
        Our website uses cookies to distinguish you from other users of our
        website. This helps us to provide you with a good experience when you
        browse our website and also allows us to improve our site.
      </p>
      <p>
        A cookie is a small file of letters and numbers that we store on your
        browser or the hard drive of your computer. Cookies contain information
        that is transferred to your computer's hard drive.
      </p>
      <p>We use the following types of cookies:</p>
      <p>
        <span class="font-semibold">Strictly necessary cookies.</span> These are
        cookies that are required for the operation of our website. They
        include, for example, cookies that enable you to log in to secure areas
        of our website, use a shopping cart or make use of e-billing services.
      </p>
      <p>
        <span class="font-semibold">Analytical/performance cookies.</span> They
        allow us to recognise and count the number of visitors and to see how
        visitors move around our website when they are using it. This helps us
        to improve the way our website works, for example, by ensuring that
        users are finding what they are looking for easily.
      </p>
      <p>
        <span class="font-semibold">Functionality cookies.</span> These are used
        to recognise you when you return to our website. This enables us to
        personalise our content for you, greet you by name and remember your
        preferences (for example, your choice of language or region).
      </p>
      <p>
        <span class="font-semibold">Targeting cookies.</span> These cookies
        record your visit to our website, the pages you have visited and the
        links you have followed. We will use this information to make our
        website and the advertising displayed on it more relevant to your
        interests. We may also share this information with third parties for
        this purpose.
      </p>
      <p class="font-semibold">Cookies that we are responsible for</p>
      <p>
        The thecoolcatclub.com website uses a single cookie named session that
        is strictly necessary for the website to operate correctly. This cookie
        identifies your website session, which is how we know who you are when
        you’re logged into the website so we can show you the correct
        information about your account. It has a 1 year expiry from when you
        last visited the website.
      </p>
      <p>
        If you block first party cookies, the thecoolcatclub.com website will no
        longer function correctly for you.
      </p>
      <p class="font-semibold">Third party cookies</p>
      <p>
        When interacting with the thecoolcatclub.com website, several third
        party cookies are stored in your browser by some of our analytics /
        performance providers and advertising partners. We do not directly
        control any of these cookies.
      </p>
      <p>
        You can visit the Interactive Advertising Bureau's website at
        <a
          class="text-teal-400 underline"
          rel="noopener"
          href="http://www.youronlinechoices.com/uk"
          >www.youronlinechoices.com/uk</a
        >
        which lists all ad-related cookies stored on your device – not just
        those related to thecoolcatclub.com – and provides additional details
        and links to control each of them.
      </p>
      <p>
        Finally, many web browsers provide blanket controls that allow you to
        block all third party cookies for all sites. We’ve included links to
        instructions on how to control third party cookie setting for the most
        popular web browsers below:
      </p>
      <p>
        <a
          class="text-teal-400 underline"
          rel="noopener"
          href="http://support.google.com/chrome/bin/answer.py?hl=en&answer=95647"
          >Chrome</a
        ><br /><a
          class="text-teal-400 underline"
          rel="noopener"
          href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
          >Internet Explorer</a
        ><br /><a
          class="text-teal-400 underline"
          rel="noopener"
          href="http://support.mozilla.org/en-US/kb/Cookies"
          >Firefox</a
        ><br /><a
          class="text-teal-400 underline"
          rel="noopener"
          href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
          >Safari</a
        ><br /><a
          class="text-teal-400 underline"
          rel="noopener"
          href="http://support.apple.com/kb/HT1677"
          >Mobile Safari (iOS)</a
        ><br /><a
          class="text-teal-400 underline"
          rel="noopener"
          href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&hl=en"
          >Android</a
        >
      </p>

      <p>
        Click
        <a class="underline" href="#" @click.prevent="disableTracking">here</a>,
        to disable the tracking through Google Analytics.
      </p>
      <p class="font-semibold">Disputes and governing law</p>
      <p>
        We will try to resolve any disputes with you quickly and efficiently
      </p>
      <p>If you are unhappy with us please contact us as soon as possible</p>
      <p>
        Please note that the Terms and Conditions and Privacy Policy, its
        subject matter and its formation, are governed by English law. If you
        want to take court proceedings, the relevant courts of England and Wales
        will have exclusive jurisdiction in relation to these Terms and
        Conditions.
      </p>
      <p>
        It applies to personal information collected by thecoolcatclub.com in
        connection with our application process. This includes information
        collected through our online application process, through third party
        recruitment agencies or recruitment related platforms.
      </p>
      <p class="font-semibold">Your consent</p>
      <p>
        By applying for a vacancy at thecoolcatclub.com and submitting your
        information through our site, you are consenting to thecoolcatclub.com
        collecting, using and disclosing your personal information in accordance
        with this Privacy Policy. If you do not agree to the collection, use and
        disclosure of your personal information in this way, please do not
        submit your information.
      </p>
      <p>
        thecoolcatclub.com will only collect, use or disclose your personal
        information where it is fair and lawful to do so.
      </p>
      <p class="font-semibold">Candidate privacy policy</p>
      <p>
        This policy explains the types of personal information
        thecoolcatclub.com collects and how we use, disclose and protect that
        information.
      </p>
      <p class="font-semibold">What does this Privacy Policy apply to?</p>
      <p>
        It applies to personal information collected by thecoolcatclub.com in
        connection with our application process. This includes information
        collected through our online application process, through third party
        recruitment agencies or recruitment related platforms.
      </p>
      <p class="font-semibold">Your consent</p>
      <p>
        By applying for a vacancy at thecoolcatclub.com and submitting your
        information through our site, you are consenting to thecoolcatclub.com
        collecting, using and disclosing your personal information in accordance
        with this Privacy Policy. If you do not agree to the collection, use and
        disclosure of your personal information in this way, please do not
        submit your information.
      </p>
      <p>
        thecoolcatclub.com will only collect, use or disclose your personal
        information where it is fair and lawful to do so.
      </p>
      <p class="font-semibold">What information do we collect?</p>
      <p>
        With "personal information" we mean information or pieces of information
        that could allow you to be identified. This typically includes
        information such as your name, address, email address, and telephone
        number, but can also include other information related to your
        application such as your passport and verification to work in the UK. We
        only collect information that we need in order to evaluate your
        application at thecoolcatclub.com.
      </p>
      <p class="font-semibold">How do we use your personal information?</p>
      <p>
        We primarily use your personal information to evaluate your suitability
        for the role you apply for and contact you (through email or phone) in
        case we wish to proceed with your application. We evaluate whether
        you’re eligible to work in the UK. We also reserve the right to send you
        surveys, asking questions about your experience of interviewing at
        thecoolcatclub.com, or contact you if we think you are suitable for
        another vacancy at thecoolcatclub.com.
      </p>
      <p>
        We store your application in our recruitment system for 15 months after
        application date. If you wish to be erased from our database or not be
        contacted for other suitable roles, please contact
        careers@thecoolcatclub.com and request your information to be deleted.
      </p>
      <p>
        Before we move to offer stage, we ask to see proof of passport and
        right-to-work documents. We store a copy of these documents in a
        confidential file and keep it for 30 days.
      </p>
      <p class="font-semibold">
        Who do we share your personal information with?
      </p>
      <p>
        Your personal information will be accessible by thecoolcatclub.com
        People Team (HR) and other parties who are part of the hiring process in
        relation to your specific application. We will not share your personal
        information with companies, organisations or individuals not connected
        with thecoolcatclub.com.
      </p>
      <p class="font-semibold">Safeguarding your personal information</p>
      <p>
        We take all reasonable precautions to keep your personal information
        secure and require any third parties that handle or process your
        personal information for us to do the same. Access to your personal
        information is restricted to prevent unauthorised access, modification
        or misuse and is only permitted among our employees and agents on a
        need-to-know basis.
      </p>
      <p class="font-semibold">Your privacy rights and who to contact</p>
      <p>
        If you have any questions, comments or concerns about how we handle your
        personal information, then please contact us on
        <a class="text-teal-400 underline" href="mailto:team@thecoolcatclub.com"
          >team@thecoolcatclub.com</a
        >
      </p>
      <p>You have the right to tell us if you:</p>
      <p>
        - would like a copy of the personal information which we hold about you
      </p>
      <p>
        - would like us to correct, update, or delete your personal information
        in our records
      </p>
      <p>- wish to report any misuse of your personal information</p>
      <p>
        To assist us in dealing with your request, please provide your full
        name, contact information and what change request you have.
      </p>
      <p class="font-semibold">Changes to our Privacy policy</p>
      <p>
        We may change this policy from time to time by posting the updated
        version on the thecoolcatclub.com career site. We encourage you to visit
        frequently to stay informed about how we use your personal information.
      </p>

      <p class="text-gray-500 text-sm mt-16">
        At some stage one of our cool cats might have asked you to clear your
        cache to help troubleshoot certain problems, this is the place to do
        it... go on, give it a click...
        <a
          @click="removeStorage()"
          class="text-teal-400 underline cursor-pointer"
          >Clear out the cat cache</a
        >
        <transition name="fade" mode="out-in">
          <span v-if="showRemoved" class=" text-sm text-gray-500 ml-4">
            <font-awesome-icon :icon="['far', 'check']" class="mr-2" /> All
            clean 😻</span
          >
        </transition>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Privacy Policy'
  },
  data() {
    return {
      showRemoved: false
    };
  },
  methods: {
    disableTracking: function() {
      this.$store.dispatch('disableTracking', true).then(() => {
        this.$ga.disable();
      });
    },
    removeStorage: function() {
      this.$store.dispatch('sendMpEvent', {
        event: 'Removed storage',
        properties: {
          location: this.$route.name,

          type: 'EVT'
        }
      });

      localStorage.removeItem('cats');
      localStorage.removeItem('plans');

      this.showRemoved = true;
    }
  }
};
</script>

<style lang="css" scoped></style>
